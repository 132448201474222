<template>
  <body
    class="dark kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading"
  >
    <section class="" style="background-color: #101826; height: 100vh">
      <div class="container-fluid py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col col-xl-11">
            <div
              class="card border-0"
              style="border-radius: 1rem; background-color: #101826"
            >
              <div
                class="row g-0 signup-container"
                style="justify-content: center"
              >
                <div
                  class="col-md-12 col-lg-5 d-flex align-items-center"
                  style="justify-content: center; position: relative"
                >
                  <div
                    class="card-body"
                    v-if="data_loader"
                    style="
                      display: flex;
                      justify-content: center;
                      position: absolute;
                    "
                  >
                    <span class="cutome-spinner"></span>
                  </div>
                  <div class="card-body" :class="is_updated">
                    <div style="display: flex; justify-content: space-between">
                      <h1
                        class="fw-normal mb-3 pb-3 text-white"
                        style="letter-spacing: 1px"
                      >
                        My Profile <br />
                      </h1>
                      <button
                        v-on:click="BackToDashboard()"
                        class="btn btn-brand fw-normal mb-3 pb-3 text-white"
                        style="background: #b60808; border: #b60808"
                      >
                        Back
                      </button>
                    </div>
                    <div size="120" class="user mb-3">
                      <img
                        v-if="!selectedFile"
                        :src="image_name ? image_name : profileImg"
                        class="profile-img"
                      />
                      <img
                        @click="cropModel()"
                        style="cursor: pointer"
                        v-if="selectedFile"
                        :src="image_name ? image_name : profileImg"
                        class="profile-img"
                      />
                      <v-icon
                        class="icons primary white--text"
                        @click="$refs.FileInput.click()"
                        >✏️</v-icon
                      >
                      <input
                        ref="FileInput"
                        type="file"
                        style="display: none"
                        @change="onFileSelect"
                      />
                    </div>

                    <div class="row">
                      <div class="col-md-6 form-outline mb-4">
                        <label
                          class="form-label text-white"
                          for="form2Example17"
                          >First Name</label
                        >

                        <input
                          type="text"
                          id="form2Example17"
                          :disabled="firstname_d"
                          class="form-control form-control-lg my"
                          v-model="firstName"
                        />
                      </div>
                      <div class="col-md-6 form-outline mb-4">
                        <label
                          class="form-label text-white"
                          for="form2Example17"
                          >Last Name</label
                        >

                        <input
                          type="text"
                          id="form2Example17"
                          class="form-control form-control-lg my"
                          v-model="lastName"
                          :disabled="lastname_d"
                        />
                      </div>
                    </div>

                    <div class="form-outline mb-4">
                      <label class="form-label text-white" for="form2Example17"
                        >Phone number</label
                      >

                      <input
                        type="companyname"
                        id="form2Example17"
                        class="form-control form-control-lg my"
                        v-model="phoneNumber"
                        :disabled="phoneNumber_d"
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <label class="form-label text-white" for="form2Example17"
                        >Display Name</label
                      >

                      <input
                        type="companyname"
                        id="form2Example17"
                        class="form-control form-control-lg my"
                        v-model="displayName"
                        :disabled="displayName_d"
                      />
                    </div>

                    <div class="form-outline mb-4">
                      <label class="form-label text-white" for="form2Example17"
                        >Company Name</label
                      >

                      <input
                        type="companyname"
                        id="form2Example17"
                        style="text-transform: lowercase"
                        class="form-control form-control-lg my"
                        v-model="companyname"
                        :disabled="company_d"
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <label class="form-label text-white" for="form2Example17"
                        >Open Room URL:</label
                      >
                      <div
                        class="form-group d-flex"
                        :class="[isCompanyValid()]"
                      >
                        <div
                          id="outer"
                          class="container"
                          style="
                            width: 80%;
                            display: flex;
                            align-items: center;
                            height: 46px;
                            font-size: 20px;
                          "
                        >
                          <div
                            class="textboxd"
                            style="
                              /* width: 22rem; */
                              position: absolute;
                              /* word-break: break-all; */
                              background: transparent;
                              color: white;
                              padding: 0 10px 0px 0px;
                              border: none;
                              right: 40%;
                              font-size: 21px;
                            "
                          >
                            {{ url }}
                          </div>
                        </div>
                        <input
                          style="
                            position: absolute;
                            left: 60%;
                            display: block;
                            font-size: 1rem;
                            font-weight: 400;
                            line-height: 1.5;
                            color: #495057;
                            outline: none;
                            background-color: #fff;
                            background-clip: padding-box;
                            border: 1px solid #e2e5ec;
                            border-radius: 4px;
                            transition: border-color 0.15s ease-in-out,
                              box-shadow 0.15s ease-in-out;
                          "
                          type="text"
                          class="form-control-lg my textbox"
                          placeholder="Name"
                          v-on:keyup="expand"
                          :disabled="name_d"
                          v-model="Name"
                        />
                      </div>
                    </div>
                    <div class="form-outline mb-4">
                      <label class="form-label text-white" for="form2Example17"
                        >Default Time Zone</label
                      >

                      <div class="">
                        <v-select
                          v-model="timezone"
                          placeholder="Timezone"
                          class="select-picker blue-border dark-bg"
                          :options="Intl.supportedValuesOf('timeZone')"
                        ></v-select>
                      </div>
                    </div>
                    <div class="form-outline mb-4">
                      <label class="form-label text-white" for="form2Example17"
                        >Email Address</label
                      >

                      <input
                        type="email"
                        id="form2Example17"
                        class="form-control form-control-lg my"
                        v-model="email"
                        :disabled="email_d"
                      />
                    </div>

                    <span
                      href="#!"
                      class="mt-1 mb-1"
                      style="color: white; float: right"
                    >
                      <router-link
                        style="color: white; font-size: medium"
                        to="change-password"
                        >Change Password</router-link
                      >
                    </span>
                    <div class="pt-1 mb-4">
                      <button
                        v-on:click="signUpTest()"
                        :disabled="save_d"
                        style="position: relative"
                        class="btn btn-primary btn-lg btn-block"
                      >
                        Save

                        <span
                          v-if="loader"
                          class="spinner spinner-white spinner-right"
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="cropImage"
        style="z-index: 9999; background: rgb(0 0 0 / 92%)"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style="width: fit-content"
          role="document"
        >
          <div class="modal-content">
            <div class="border-dotted">
              <div
                class="modal-body-crop"
                style="border: 1px solid; border-radius: 6px; height: 60%"
              >
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <h1
                          class="text-white text-center"
                          style="font-size: 1.5rem"
                        >
                          Crop Picture
                        </h1>
                      </div>
                    </div>
                    <div class="row mt-3 mb-3" style="justify-content: center">
                      <div>
                        <v-dialog v-model="dialog" width="500">
                          <v-card>
                            <v-card-text>
                              <VueCropper
                                v-show="selectedFile"
                                ref="cropper"
                                :src="selectedFile"
                                :aspect-ratio="1"
                                :view-mode="1"
                                alt="Source Image"
                                :minContainerWidth="400"
                                :minContainerHeight="400"
                              >
                              </VueCropper>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row mt-3 text-center align-items-center justify-content-center"
                >
                  <button
                    @click="saveImage(), (dialog = false)"
                    type="button"
                    class="btn white-btn mr-3"
                    style="width: 150px; position: relative"
                    :disabled="edit_contact_enable"
                  >
                    <span
                      v-if="edit_loader"
                      class="spinner spinner-white spinner-right"
                    ></span>
                    Save
                  </button>
                  <button
                    type="button"
                    class="btn transparent-btn border-blue"
                    style="width: 150px"
                    @click="closeModal()"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </body>
</template>

<script>
import { mapState } from "vuex";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import {
  lastwebinarlist,
  signUpPost,
  updateUserDetails,
  userEmail,
} from "../services/service";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
export default {
  components: { VueCropper, vSelect },
  props: ["image_name"],
  name: "My Profile",
  data() {
    return {
      Name: "",
      email: "",
      firstName: "",
      timezone: "",
      lastName: "",
      password: "",
      displayName: "",
      phoneNumber: "",
      companyname: "",
      oldRoomName: "",
      room_name: "",
      confirmPassword: "",
      is_updated: "card-opacity",
      url: "",
      showPassword: false,
      loader: false,
      data_loader: true,
      firstname_d: true,
      lastname_d: true,
      displayName_d: true,
      phoneNumber_d: true,
      company_d: true,
      name_d: true,
      email_d: true,
      save_d: true,
      mime_type: "",
      profileImg: "",
      cropedImage: "",
      autoCrop: false,
      selectedFile: "",
      image: "",
      dialog: false,
      image_name: "",
      files: "",
    };
  },
  async mounted() {
    document.querySelector(".textbox").style.width = "157px";

    if (!localStorage.getItem("isAuthenticated")) {
      this.$router.push("/login");
    }
  },
  async created() {
    let data = await userEmail();
    console.log("data-0-----", data);
    if (data.response) {
      if (data.response.data.code == 401 || data.response.status == 401) {
        localStorage.clear();
        swal(data.response.data.message);
        this.$router.push("/login");
      }
    }

    if (!localStorage.getItem("isAuthenticated")) {
      this.$router.push("/login");
    } else {
      this.updateData();
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    saveImage() {
      console.log("this.image_name-----------");
      console.log("this.cropedImage-----------");

      // const userId = this.$route.params.user_id
      this.cropedImage = this.$refs.cropper
        .getCroppedCanvas({
          width: 350,
          height: 350,
        })
        .toDataURL();
      // this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        const formData = new FormData();
        formData.append("profile_photo", blob, "name.jpg");

        this.image_name = this.cropedImage;
        console.log("blob-----------", blob);
        console.log("this.cropedImage-----------", this.cropedImage);
        console.log("formData-----------", formData);

        // this.mime_type = "";
        // this.selectedFile = "";
        // this.cropedImage = "";
        // this.image_name = "";
        $("#cropImage").modal("hide");
        // axios
        //   .post('/api/user/' + userId + '/profile-photo', formData)
        //   .then((response) => {
        //   })
        //   .catch(function (error) {
        //     console.log(error)
        //   })
      }, this.mime_type);
    },
    cropModel() {
      // this.selectedFile = "";
      $("#cropImage").modal("show");
    },
    closeModal() {
      $("#cropImage").modal("hide");
    },
    onFileSelect(e) {
      const file = e.target.files[0];
      if (this.selectedFile) {
        $("#cropImage").modal("show");
      }
      console.log("file_________", file);
      this.mime_type = file.type;
      console.log(this.mime_type);
      const maxSizeMB = 3;
      const maxSizeBytes = maxSizeMB * 1024 * 1024;
      const getSize = (file?.size / (1024 * 1024)).toFixed(2);

      if (file?.size > maxSizeBytes) {
        alert(
          `The file size ${getSize} of the image you uploaded is too big. (Max = 3MB)`
        );
        return; // Exit the function to prevent further processing
      }
      if (typeof FileReader === "function") {
        this.dialog = true;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedFile = event.target.result;
          this.$refs.cropper.replace(this.selectedFile);

          $("#cropImage").modal("show");
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      e.target.value = null;
    },
    async updateData() {
      this.data_loader = true;
      const body = {
        is_edit: 0,
      };
      const userData = await updateUserDetails(body);
      console.log("userData---------", userData.data.result.data);
      const lastwebinar = await lastwebinarlist();

      if (lastwebinar.data.result.data != null) {
        var userName = lastwebinar.data.result.data.roomName;
      } else {
        var userName = userData.data.result.data.Name;
      }
      this.oldRoomName = userData.data.result.data.Name;
      this.Name = userName;
      this.email = userData.data.result.data.email;
      const BASE_URL =
        window.location.host == "unitywebinar.com"
          ? "https://api.unitywebinar.com/"
          : window.location.host == "dev.unitywebinar.com"
          ? "https://dev-api.unitywebinar.com/"
          : "https://api.unitywebinar.com/";

      this.profileImg = BASE_URL + userData.data.result.data.profileImg;
      this.firstName = userData.data.result.data.firstName;
      this.phoneNumber = userData.data.result.data.phoneNumber;
      this.lastName = userData.data.result.data.lastName;
      this.timezone = userData.data.result.data.timezone;
      this.companyname = userData.data.result.data.companyname;

      if (
        userData.data.result.data.display_name == "" ||
        !userData.data.result.data.display_name
      ) {
        this.displayName = this.firstName + " " + this.lastName;
      } else {
        this.displayName = userData.data.result.data.display_name;
      }
      var old_url = window.location.origin + "/" + this.companyname + "/";
      this.url = old_url;
      this.data_loader = false;
      this.is_updated = "";
      this.firstname_d = false;
      this.lastname_d = false;
      this.company_d = false;
      this.displayName_d = false;
      this.phoneNumber_d = false;
      this.name_d = false;
      this.email_d = false;
      this.save_d = false;
    },
    async expand() {
      this.Name = this.Name.replace(/[^a-z0-9]/gi, "").replaceAll(" ", "");
      const textbox = document.querySelector(".textbox");

      if (!textbox.startW) {
        textbox.startW = textbox.offsetWidth;
      }

      var style = textbox.style;

      //Force complete recalculation of width
      //in case characters are deleted and not added:
      style.width = 0;

      var desiredW = textbox.scrollWidth;
      //Optional padding to reduce "jerkyness" when typing:
      desiredW += textbox.offsetHeight;

      style.width = Math.max(desiredW, textbox.startW) - 40 + "px";
    },
    isCompanyValid: async function async() {
      console.log("this.---------", this.companyname);

      var old_url =
        window.location.origin +
        "/" +
        this.companyname.toLocaleLowerCase().replace(/[^a-z0-9]/gi, "") +
        "/";
      this.url = old_url;
      this.processInput();
      // this.companyname
    },
    async processInput() {
      const output = document.querySelector(".output");
      if (output) {
        output.style.fontSize = "18px"; // Default font size
        this.resize_to_fit();
      }
    },
    async resize_to_fit() {
      const output = document.querySelector(".output");
      let fontSize = window.getComputedStyle(output).fontSize;
      output.style.fontSize = parseFloat(fontSize) - 1 + "px";

      const outputContainer = document.querySelector(".container");

      if (output.clientHeight >= outputContainer.clientHeight) {
        this.resize_to_fit();
      }
    },
    async myFunction() {
      // Get the text field
      var copyText = document.getElementById("myInput");

      // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);

      // Alert the copied text
      alert("Copy Text to Clipboard ");
    },

    async BackToDashboard() {
      this.$router.push("/calendar");
    },
    async hidePassword() {
      this.showPassword = !this.showPassword;
    },
    async signUpTest() {
      this.loader = true;

      let body = {
        Name: this.Name.toLocaleLowerCase()
          .replace(/[^a-z0-9]/gi, "")
          .replaceAll(" ", ""),
        email: this.email,
        oldRoomName: this.oldRoomName,
        firstName: this.firstName,
        display_name: this.displayName,
        phoneNumber: this.phoneNumber,
        lastName: this.lastName,
        timezone: this.timezone,
        companyname: this.companyname
          .toLocaleLowerCase()
          .replaceAll(" ", "")
          .replace(/[^a-z0-9]/gi, ""),
        is_edit: 1,
      };
      if (this.image_name) {
        body = { ...body, base64: this.image_name };
      }
      if (this.Name == "") {
        this.loader = false;
        swal("Please enter your name.");
      } else if (this.email == "") {
        this.loader = false;
        swal("Please enter your email.");
      } else if (this.firstName == "") {
        this.loader = false;
        swal("Please enter your first name.");
      } else if (this.lastName == "") {
        this.loader = false;
      } else if (this.displayName == "") {
        this.loader = false;
        swal("Please enter your display name.");
      } else if (this.companyname == "") {
        this.loader = false;
        swal("Please enter your company name.");
      } else {
        let data = await updateUserDetails(body);

        if (data.status == 200) {
          const body = {
            is_edit: 0,
          };
          this.updateData();
          this.image_name = "";
          await localStorage.setItem(
            "userdetails",
            JSON.stringify(data.data.result.data)
          );

          window.location.href = "calendar";
          this.$router.push("/calendar");
          // window.location.reload();
          this.loader = false;
          swal(data.data.message);
        } else {
          console.log("data---", data);
          if (data.response) {
            if (data.response.data.code == 401 || data.response.status == 401) {
              localStorage.clear();
              swal(data.response.data.message);
              this.$router.push("/login");
            } else if (
              data.response.data.code == 400 ||
              data.response.status == 400
            ) {
              swal(data.response.data.message);
            } else {
              swal(data.response.statusText);
              console.log("rrrrrrr---------", data);
            }
          } else {
            swal(
              "The file size of the image you uploaded is too big. (Max = 3MB)"
            );
            // if (data.response.status == 413) {
            //   swal(data.response.statusText);
            //   console.log("rrrrrrr--22-------", data);
            // }else {
            //   console.log("rrrrrrr----33-----", data);

            // }
          }
          this.loader = false;
          // swal(data.response.data.message);
        }

        this.selectedFile = "";
        this.image_name = "";
        this.cropedImage = "";
      }
    },
  },
};
</script>

<style>
.my {
  background: white !important;
}

.cutome-spinner {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner 0.6s linear infinite;
}

.card-opacity {
  opacity: 0.2;
}

.user {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  border-radius: 100%;
  border: 3px solid #ffffff;
  position: relative;
}

.profile-img {
  /* height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden; */
  height: 120px;
  width: 120px;
  border-radius: 50%;
  object-fit: contain;
  overflow: hidden;
}

.icons {
  position: absolute;
  top: 10px;
  right: 0;
  background: #e2e2e2;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  color: #0000ff;
  font-size: 14px;
  cursor: pointer;
}

.vs__selected {
  color: white !important;
}
.modal-body-crop {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 3rem;
}
</style>

import axios from "axios";
const BASE_URL =
  window.location.host == "unitywebinar.com"
    ? "https://api.unitywebinar.com/"
    : window.location.host == "dev.unitywebinar.com"
    ? "https://dev-api.unitywebinar.com/"
    : "https://api.unitywebinar.com/";
// const BASE_URL = 'http://localhost:8000/';

export async function signUpPost(body) {
  let headers = {
    "Content-type": "application/json",
  };
  let data = await axios
    .post(BASE_URL + "api/users/v1/signUp", body, headers)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function deleteRecording(body) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("user"),
  };
  let data = await axios
    .post(BASE_URL + "api/users/v1/webinar/removeFile/", body, headers)
    .then(function (response) {
      console.log("response---------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function deleteChat(body) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("user"),
  };
  let data = await axios
    .post(BASE_URL + "api/users/v1/delete-chat", body, {
      headers: headers
    })
    .then(function (response) {
      console.log("response---------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function unlinkRecording(link) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("user"),
  };
  let data = await axios
    .get(
      BASE_URL + "api/users/v1/webinar/removeFile/" + link,
      {},
      {
        headers: headers,
      }
    )
    .then(function (response) {
      console.log("response---------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function downloadRecording(link) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("user"),
  };
  let data = await axios
    .get(
      BASE_URL + "api/users/v1/webinar/downloadFile/" + link,
      {},
      {
        headers: headers,
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function lastwebinarlist(roomName) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("user"),
  };
  let data = await axios
    .post(
      BASE_URL + "api/users/v1/webinar/lastwebinarlist",
      {},
      {
        headers: headers,
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function webinarlist(title) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("user"),
  };
  let data = await axios
    .post(
      BASE_URL + "api/users/v1/webinar/list",
      {},
      {
        headers: headers,
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function getMyPolls(body) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("user"),
  };
  let data = await axios
    .post(
      BASE_URL + "api/users/v1/getMyPolls",
      {},
      {
        headers: headers,
      }
    )
    .then(function (response) {
      console.log("-----response----------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function addToVote(body) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("user"),
  };
  let data = await axios
    .post(BASE_URL + "api/users/v1/addToVote", body, {
      headers: headers,
    })
    .then(function (response) {
      console.log("-----response----------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function createPoll(body) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("user"),
  };
  let data = await axios
    .post(BASE_URL + "api/users/v1/createPoll", body, {
      headers: headers,
    })
    .then(function (response) {
      console.log("-----response----------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function listupdateParticipants(body) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("user"),
  };
  let data = await axios
    .put(BASE_URL + "api/users/v1/webinar/listupdateParticipants", body)
    .then(function (response) {
      console.log("-----response----------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function editSaveContacts(body) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("user"),
  };
  let data = await axios
    .post(BASE_URL + "api/users/v1/editContacts", body, {
      headers: headers,
    })
    .then(function (response) {
      console.log("-----response----------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function checkResetLink(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/checkResetLink", body)
    .then(function (response) {
      console.log("-----response----------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function changePasswordFromProfile(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/changePasswordFromProfile", body)
    .then(function (response) {
      console.log("-----response----------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}
export async function sendResetPassword(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/sendResetPassword", body)
    .then(function (response) {
      console.log("-----response----------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function resendInvite(body) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("user"),
  };
  let data = await axios
    .post(BASE_URL + "api/users/v1/webinar/resendInvite", body, {
      headers: headers,
    })
    .then(function (response) {
      console.log("-----response----------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function deleteWebinars(body) {
  let url = BASE_URL + `api/users/v1/webinar/delete/${body.webinarDetails}`;
  if (body.mid != 0) {
    url += `/${body.mid}`;
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("user"),
  };
  let data = await axios
    .post(url, body, {
      headers: headers,
    })
    .then(function (response) {
      console.log("-----response----------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function participants(body) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("user"),
  };
  let data = await axios
    .post(BASE_URL + "api/users/v1/webinar/participants", body, {
      headers: headers,
    })
    .then(function (response) {
      console.log("-----response----------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function checkWebinar(title) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/webinar/details", {
      roomName: title,
    })
    .then(function (response) {
      console.log("-----response----------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function remove_document(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/remove_document", body)
    .then(function (response) {
      console.log("-----response----------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function upload_ppt_file(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/upload_ppt_file", body)
    .then(function (response) {
      console.log("-----response----------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function verifyOpenRoomWebinars(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/webinar/verifyOpenRoomWebinars", body)
    .then(function (response) {
      console.log("-----response----------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function checkWebinarUrl(roomName) {
  console.log(
    "JSON.parse(localStorage.getItem=._id)-----------------",
    JSON.parse(localStorage.getItem("userdetails"))._id
  );
  let data = await axios
    .post(BASE_URL + "api/users/v1/webinar/check_url", {
      roomName: roomName,
      userId: JSON.parse(localStorage.getItem("userdetails"))._id,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function checkWebinarParticipantEmail(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/webinar/check_email", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function checkContactEmail(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/getContactEmail", body, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function loginPost(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/login", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function startRecording(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/webinar/startRecording", body, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function stopRecording(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/webinar/stopRecording", body, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function updateOpenRoomSetting(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/updateOpenRoomSetting", body, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function moveFileToFolder(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/moveFileToFolder", body, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function getFoldersFiles(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/getFoldersFiles", body, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function createFolderData(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/createFolder", body, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function updateFolderData(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/updateFolderData", body, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function removeFile(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/removeFile", body, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function getMyFolder() {
  let data = await axios
    .post(
      BASE_URL + "api/users/v1/getMyFolder",
      {},
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("user"),
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function updateUserDetails(body) {
  let data = await axios
    .post(BASE_URL + "api/users/v1/updateUsers", body, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });

  return data;
}

export async function webinarParticipateUserList(body) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("user"),
  };
  // let bodyData = body;
  let data = await axios
    .get(BASE_URL + `api/users/v1/webinar_participate_user_list/${body}`)
    .then(function (response) {
      console.log("response--------------", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function lockUnlockOpenRoom(body) {
  let bodyData = body;
  let data = await axios
    .post(BASE_URL + "api/users/v1/webinar/lockUnlockOpenRoom", bodyData, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function updateWebinarType(body) {
  let bodyData = body;
  let data = await axios
    .post(BASE_URL + "api/users/v1/webinar/updateWebinarType", bodyData, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}
export async function webinaroneTime(body) {
  let bodyData = body;
  let data = await axios
    .put(BASE_URL + "api/users/v1/webinar", bodyData, {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function updateDateTimeForWebinars(body) {
  // let bodyData = {
  //   title: body.title,
  //   webinarType: "ONE_TIME",
  //   oneTime: {
  // date: body.title,
  //     time: body.oneTime.time,
  //     duration: body.oneTime.duration,
  //   },
  //   newUser: body.users,
  // };
  let bodyData = body;
  let data = await axios
    .post(
      BASE_URL + "api/users/v1/webinar/updateDateTimeForWebinars",
      bodyData,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("user"),
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function webinarmalti(body) {
  let headers = {
    "Content-Type": "application/json",
    authorization: localStorage.getItem("user"),
  };
  let data = await axios
    .put(BASE_URL + "api/users/v1/webinar", body, {
      headers: headers,
    })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
  return data;
}

export async function getSingleWebinarData(id) {
  let url = BASE_URL + "api/users/v1/webinar/" + id;

  console.log("url-----------", url);
  let data = await axios
    .get(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function singlewebinardata(id, mid = 0) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("user"),
  };
  console.log("mid--->", mid);
  let url = BASE_URL + "api/users/v1/webinar/";
  if (mid != undefined && mid != 0) {
    url += `${id}/${mid}`;
  } else {
    url += `${id}`;
  }

  console.log("url-----------", url);
  let data = await axios
    .get(url, { headers: headers })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function verifywebinars(body) {
  console.log("body---------------------------", body);
  let data = await axios
    .post(BASE_URL + `api/users/v1/webinar/verifyWebinars`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function getMyContacts() {
  let data = await axios
    .post(
      BASE_URL + `api/users/v1/getMyContacts`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("user"),
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function deleteContacts(body) {
  let data = await axios
    .post(BASE_URL + `api/users/v1/deleteContacts`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function CheckContacts(email) {
  let data = await axios
    .post(
      BASE_URL + `api/users/v1/CheckContacts`,
      { email: email },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("user"),
        },
      }
    )
    .then(function (response) {
      return response.data.result;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function getParticipant(body) {
  let data = await axios
    .post(BASE_URL + `api/users/v1/get-participant`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function getRecording(body) {
  let data = await axios
    .post(BASE_URL + `api/users/v1/get-recording`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function getChat(body) {
  let data = await axios
    .post(BASE_URL + `api/users/v1/get-chat`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function getMessages(roomId) {
  let data = await axios
    .get(BASE_URL + `api/users/v1/get-messages/${roomId}`, {}, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function addToRecording(body) {
  let data = await axios
    .post(BASE_URL + `api/users/v1/upload-recording`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function addToContacts(body) {
  let data = await axios
    .post(BASE_URL + `api/users/v1/addToContacts`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function userEmail() {
  let data = await axios
    .get(BASE_URL + `api/users/v1/userEmail`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function checkCompany(body) {
  let data = await axios
    .post(BASE_URL + `api/users/v1/getUserCompany`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      console.log("------------sdd");
      console.log(response);
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function removeParticipants(body) {
  console.log("body-->", body);
  let data = await axios
    .post(BASE_URL + `api/users/v1/webinar/removeParticipants`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      console.log("response???????????", response);
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

export async function liveWebinar(body) {
  let data = await axios
    .post(BASE_URL + `api/users/v1/webinar/live`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("user"),
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
  return data;
}

<template>
  <body
    class="dark kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading"
  >
    <section class="vh-100" style="background-color: #101826">
      <div class="container-fluid py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col col-xl-11">
            <div
              class="card border-0"
              style="border-radius: 1rem; background-color: #101826"
            >
              <div class="row g-0">
                <div class="col-md-6 col-lg-5 d-flex align-items-center">
                  <div class="card-body p-4 p-lg-5">
                    <form>
                      <h1
                        class="fw-normal mb-3 pb-3 text-white"
                        style="letter-spacing: 1px"
                      >
                        Login<br />
                      </h1>

                      <div class="form-outline mb-4">
                        <label
                          class="form-label text-white"
                          for="form2Example17"
                          >Email address</label
                        >

                        <input
                          type="email"
                          id="form2Example17"
                          class="form-control form-control-lg my"
                          v-model="email"
                        />
                      </div>

                      <label class="form-label text-white" for="form2Example27"
                        >Password</label
                      >

                      <div class="form-outline input-group mb-4">
                        <input
                          v-if="showPassword"
                          type="text"
                          id="form2Example27"
                          class="form-control form-control-lg my"
                          v-model="password"
                        />
                        <input
                          v-else
                          type="password"
                          id="form2Example27"
                          class="form-control form-control-lg my"
                          v-model="password"
                        />
                        <div class="input-group-append" @click="hidePassword">
                          <span class="input-group-text" id="basic-addon2"
                            ><svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-eye"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                              />
                              <path
                                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                              /></svg
                          ></span>
                        </div>
                      </div>
                      <a class="small" href="#!">
                        <router-link to="forgot-password"
                          >Forgot password?
                        </router-link></a
                      >

                      <div class="pt-1 mb-4">
                        <button
                          v-on:click="login()"
                          style="position: relative"
                          class="btn btn-primary btn-lg btn-block"
                          type="button"
                          :disabled="loader"
                        >
                          Login
                          <span
                            v-if="loader"
                            class="spinner spinner-white spinner-right"
                          ></span>
                        </button>
                      </div>

                      <!-- <p class="mb-5 pb-lg-2" style="color: #ffff;">
                        Don't have an account?
                        <a href="#!" style="color: #2d8cff;">
                          <router-link to="sign-up">SignUp </router-link>
                        </a>
                      </p> -->
                    </form>
                  </div>
                </div>

                <div
                  class="col-md-6 col-lg-7 d-none d-md-block"
                  style="
                    color: #78b0ed;
                    background-image: linear-gradient(
                      200deg,
                      rgba(172, 206, 247, 1),
                      rgba(240, 210, 238, 1)
                    );
                    border-radius: 1rem 1rem;
                  "
                >
                  <div class="fw-normal mt-5 mb-2 pb-5 text-dark text-center">
                    <img
                      alt="Logo"
                      width="350"
                      height="62"
                      src="../../assets/media/UW@600x.png"
                    />
                  </div>
                  <div class="text-center mb-5" style="color: #3e4c64">
                    <img
                      src="../../assets/media/broadcast.jpg"
                      alt="login form"
                      class="img-fluid w-50"
                      style="border-radius: 1rem 0 0 1rem"
                    />
                    <h4 style="color: #3e4c64">
                      The <b>Most Powerful</b> webinar software
                    </h4>
                    <p>
                      webinar software that puts you in control of your
                      broadcasts.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </body>
</template>

<script>
import { loginPost } from "../services/service";
import { mapActions } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      loader: false,
    };
  },
  created() {
    console.log("come", localStorage.getItem("isAuthenticated"));
    if (localStorage.getItem("isAuthenticated")) {
      this.$router.push("/calendar");
    }
  },
  methods: {
    ...mapActions(["LogIn"]),
    async hidePassword() {
      this.showPassword = !this.showPassword;
    },
    async login() {
      let body = {
        email: this.email,
        password: this.password,
      };
      if (
        this.email == "" ||
        this.email == null ||
        this.password == "" ||
        this.password == null
      ) {
        swal("Please enter email and password.");
      } else {
        this.loader = true;

        let data = await loginPost(body);
        if (data.status == 200) {
          console.log(data.data.result);
          console.log(data.data.result.token);
          await localStorage.setItem(
            "userdetails",
            JSON.stringify(data.data.result)
          );
          await localStorage.setItem("user", data.data.result.token);
          await localStorage.setItem("isAuthenticated", true);
          this.$router.push("/calendar");
          console.log(data.data.message);

          swal(data.data.message);
        } else {
          swal("Incorrect credentials");
          this.loader = false;
        }
      }
    },
  },
};
</script>

<style>
.my {
  background: white !important;
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import login from "../login/login.vue";
import SignUp from "../Sign-Up/SignUp.vue";
import forgotPassword from "../Sign-Up/forgotPassword.vue";
import ResetPassword from "../Sign-Up/ResetPassword.vue";
import MyProfile from "../Sign-Up/MyProfile.vue";
import ChangePassword from "../Sign-Up/ChangePassword.vue";
import Recordings from "../Sign-Up/Recordings.vue";
import Participants from "../Sign-Up/Participants.vue";
import MediaManager from "../Sign-Up/MediaManager.vue";
import removedPage from "../Sign-Up/removedMeeting.vue";
import Settings from "../Sign-Up/Settings.vue";
import Drag from "../Sign-Up/Drag.vue";
import WebinarModel from "../WebinarModel/WebinarModel.vue";
import Guestlist from "../Guestlist/Guestlist.vue";
import videopageTest from "../videopage/videopage-test.vue";
// import videopageRecord from "../videopage/videopage-meetings.vue";
import videopageRecord from "../videopage/videopage-meetings-new.vue";
import videopageTestRecord from "../videopage/videopage-test-meetings.vue";
import videoPageMain from "../videopage/videoPageMain.vue";
import webinar from "../webinar/webinar.vue";
import OnBoarding from "../webinar/OnBoarding.vue";
import Wait from "../webinar/wait.vue";
import store from "../store";
import Chats from "@/Sign-Up/Chats.vue";

const routes = [
  {
    component: login,
    path: "/",
    meta: { guest: true, title: "Unity Webinar" },
  },
  {
    name: "login",
    component: login,
    path: "/login",
    meta: { guest: true, title: "Unity Webinar" },
  },

  {
    name: "SignUp",
    component: SignUp,
    path: "/Sign-Up",
    meta: { guest: true, title: "Unity Webinar" },
  },
  {
    name: "changePassword",
    component: ChangePassword,
    path: "/change-password",
    meta: { title: "Unity Webinar" },
  },
  {
    name: "left",
    component: removedPage,
    path: "/left",
    meta: { guest: true, title: "Unity Webinar" },
  },
  {
    name: "profile",
    component: MyProfile,
    path: "/profile",
    meta: { guest: true, title: "Unity Webinar" },
  },
  {
    name: "WebinarModel",
    component: WebinarModel,
    path: "/WebinarModel",
    meta: { title: "Unity Webinar" },
  },
  {
    name: "calendar",
    component: Guestlist,
    path: "/calendar",
  },
  {
    name: "Recordings",
    component: Recordings,
    path: "/recordings",
    meta: { title: "Unity Webinar" },
  },
  {
    name: "Chats",
    component: Chats,
    path: "/chats",
    meta: { title: "Unity Webinar" },
  },
  {
    name: "Drag",
    component: Drag,
    path: "/drag",
  },
  {
    name: "participants",
    component: Participants,
    path: "/participants",
    meta: { title: "Unity Webinar" },
  },
  {
    name: "MediaManager",
    component: MediaManager,
    path: "/media-manager",
    meta: { title: "Unity Webinar" },
  },
  {
    name: "settings",
    component: Settings,
    path: "/settings",
    meta: { title: "Unity Webinar" },
  },
  {
    name: "join",
    component: videopageTest,
    path: "/join",
    meta: { title: "UW - Unity Webinar" },
  },
  {
    name: "record/join",
    component: videopageRecord,
    path: "/record/join",
    meta: { title: "UW - Unity Webinar" },
  },
  {
    name: "record",
    component: videopageRecord,
    path: "/record/:company/:user",
    meta: { title: "UW - Unity Webinar" },
  },
  {
    name: "Testrecord",
    component: videopageTestRecord,
    path: "/roommeeting/:company/:user",
    meta: { title: "UW - Unity Webinar" },
  },
  {
    name: "forgot",
    component: forgotPassword,
    path: "/forgot-password",
  },
  {
    name: "ResetPassword",
    component: ResetPassword,
    path: "/ResetPassword",
  },
  {
    name: "testJoin",
    component: videopageTest,
    path: "/:company/:user",
    meta: { title: "UW - Unity Webinar" },
  },
  {
    name: "webinar",
    component: webinar,
    path: "/webinar",
  },
  {
    name: "onboard",
    component: OnBoarding,
    path: "/onboard",
  },
  {
    name: "wait",
    component: Wait,
    path: "/wait",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
